import { Formik, Form } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Row } from "reactstrap";
import * as Yup from "yup";
import { newUserAction } from "../../../redux/actions/auth/auth.actions";
import { useDispatch } from "react-redux";
import { MdEmail, MdInfo } from "react-icons/md";
import { IoMdLock } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import OtpModel from "../../../components/OtpModel";

const Signup = ({setresMsg}) => {
  const navigate = useNavigate();
  const {state} = useLocation();
  console.log(state,"i amstate")
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const [otpModal, setOTPModal] = useState(false);
  const dispatch = useDispatch(); // disptach
  //Initial Values
  const defaultValues = {
    bsType: "personal",
    bsCategory: "BsCategory",
    bsName: "",
    fsName: "",
    firstName: "",
    gender: "Gender",
    lastName: "",
    date: "DD",
    month: "MM",
    year: "YY",
    email: "",
    password: "",
    confirmPassword: "",
  };
  //Schema for Error Formik
  const SignupSchema = Yup.object().shape({
    bsType: Yup.string().required(),
    bsCategory: Yup.string().when("bsType", {
      is: "personal",
      then: Yup.string().nullable(),
    }),
    bsName: Yup.string().when("bsType", {
      is: "personal",
      then: Yup.string().nullable(),
    }),
    fsName: Yup.string().when("bsType", {
      is: "personal",
      then: Yup.string().nullable(),
    }),
    gender: Yup.string().when("bsType", {
      is: "business_account",
      then: Yup.string().nullable(),
    }),
    firstName: Yup.string().when("bsType", {
      is: "business_account",
      then: Yup.string().nullable(),
    }),
    lastName: Yup.string().ensure().when("bsType", {
      is: "business_account",
      then: Yup.string().nullable(),
    }),
    date: Yup.string().when("bsType", {
      is: "business_account",
      then: Yup.string().nullable(),
    }),
    month: Yup.string().ensure().when("bsType", {
      is: "business_account",
      then: Yup.string().nullable(),
    }),
    year: Yup.string().ensure().when("bsType", {
      is: "business_account",
      then: Yup.string().nullable(),
    }),
    email: Yup.string().email("Invalid email format").required("Required"),
    password: Yup.string().required(),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required(),
  });
  // const [ErrorField, setErrorField] = useState("");
  //DOB Create
  const monthData = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const days = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];
  const [year, setYear] = useState([]);
  const yearsMake = () => {
    const currentYear = new Date().getFullYear();
    const range = (start, stop, step) =>
      Array.from(
        { length: (stop - start) / step + 1 },
        (_, i) => start + i * step
      );
    setYear(range(currentYear, currentYear - 122, -1));
  };
  useEffect(() => {
    yearsMake();
  }, []);   // eslint-disable-line react-hooks/exhaustive-deps

  //Business List
  const businessTypes = [
    { id: 0, name: "Real Estate / Construction" },
    { id: 1, name: "Food / Beverage" },
    { id: 2, name: "Clothing / Apparel" },
    { id: 3, name: "Coaching / Consultation" },
    { id: 4, name: "Antiques" },
    { id: 5, name: "Art" },
    { id: 6, name: "Books" },
    { id: 7, name: "Electronics" },
    { id: 8, name: "Music" },
    { id: 9, name: "Home / Garden" },
    { id: 10, name: "Beauty / Wellness" },
    { id: 11, name: "Sports" },
    { id: 12, name: "Entertainment" },
    { id: 13, name: "Culture" },
    { id: 14, name: "Vehicles" },
    { id: 15, name: "Furniture" },
    { id: 16, name: "Fabric / Textiles" },
    { id: 17, name: "Medical / Dental" },
    { id: 18, name: "Health / Fitness" },
    { id: 19, name: "Tools / Hardware" },
    { id: 20, name: "Toys / Hobbies / Crafts" },
    { id: 21, name: "Luggage / Bags / Cases" },
    { id: 22, name: "Business Services" },
    { id: 23, name: "Industrial" },
    { id: 24, name: "Outdoors / Recreation" },
    { id: 25, name: "Hotels / Motels / Resorts" },
  ];
  // Payload to send
  const payloadObj = {
    fname: "",
    gender: "",
    email: "",
    dob: "",
    password: "",
    type: 0,
    business_name: "",
    business_category: "",
  };
  const payloadObjfunc = (values) => {
    let email = values?.email.toLowerCase()
    values.bsType === "business_account"
      ? (payloadObj.type = 1)
      : (payloadObj.type = 0);
    payloadObj.fname =
      values.bsType === "business_account" ? "" : values.firstName;
    payloadObj.lname =
      values.bsType === "business_account" ? "" : values.lastName;
    payloadObj.gender =
      values.bsType === "business_account" ? "" : values.gender;
    payloadObj.dob =
      values.bsType === "business_account"
        ? ""
        : `${values.month}-${values.date}-${values.year}`;
    payloadObj.password = values.password;
    payloadObj.email = email;
    payloadObj.business_name =
      values.bsType === "personal" ? "" : values.bsName;
    payloadObj.user_name =
      values.bsType === "personal" ? "" : values.fsName;
    payloadObj.business_category =
      values.bsType === "personal" ? "" : values.bsCategory;
  };
  //handle Submit
  const handleSubmit = (fdata) => {
    // setresMsg("")
    payloadObjfunc(fdata);
    let dob = new Date(payloadObj.dob)
    var month_diff = Date.now() - dob.getTime();
    var age_dt = new Date(month_diff);
    //extract year from date
    var year = age_dt.getUTCFullYear();
    //now calculate the age of the user
    var age = Math.abs(year - 1970);
    if(fdata.bsType==="personal" || fdata.bsType==="business_account"){
      dispatch(newUserAction(payloadObj))
      .then((res) =>{
        console.log(res.user.status);
        if(res.user.status === 200){
          setresMsg(res?.user?.message);
          setOTPModal(!false)
        } else {
          setresMsg(res?.user?.message);
        }
      });
    }
  };
  function signUpPageChange() {
    navigate("/login")
  }
  return (
    <>
      <Formik
        initialValues={defaultValues}
        onSubmit={handleSubmit}
        validationSchema={SignupSchema}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          touched,
          isValid,
          dirty,
        }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <div>
              <div className="position-relative d-flex justify-content-center px-4">
                <select
                  className="form-select form-select-sm AccountType"
                  name="bsType"
                  id="bsType"
                  onChange={handleChange}
                  defaultValue={state?"business_account":"Type"}
                >
                  <option disabled hidden>
                    Type
                  </option>
                  <option value="personal" className="options">Personal Account</option>
                  <option value="business_account" className="options">Business Account</option>
                </select>
                {errors.bsType && touched.bsType ? (
                  <div className="error-text">{errors.bsType}</div>
                ) : null}
              </div>
              {values.bsType === "personal" && state == null ? (
                <>
                  {/* ///////// Personal Account///////////////// */}
                  <>
                    <div className="mt-3 position-relative px-4">
                    <label className="Labelsignup">First Name</label>
                    <MdInfo className="Mdinfo" color="black" size={20}/>
                      <input
                        className="form-control input-btn padd"
                        type="text"
                        placeholder="First Name"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                      />
                      {errors.firstName && touched.firstName ? (
                        <div className="error-text">{errors.firstName}</div>
                      ) : null}
                    </div>
                    <div className="mt-3 position-relative px-4">
                    <label className="Labelsignup">Last Name</label>
                    <MdInfo className="Mdinfo" color="black" size={20}/>
                      <input
                        className="form-control input-btn padd"
                        type="text"
                        placeholder="Last Name"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                      />
                      {errors.lastName && touched.lastName ? (
                        <div className="error-text">{errors.lastName}</div>
                      ) : null}
                    </div>
                    <div className="mt-3 position-relative px-4">
                    <label className="Labelsignup">Email Address</label>
                    <MdEmail className="Mdinfo" color="black" size={20}/>
                      <input
                        className="form-control input-btn padd"
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        autoComplete="off"
                        value={values?.email}
                        onChange={handleChange}
                      />
                      {/* {errors.email && touched.email
                        ? setErrorField(errors.email)
                        : null} */}
                    </div>
                    <div className="mt-3 position-relative px-4">
                      <select
                        className="form-select form-select-sm AccountType"
                        name="gender"
                        value={values.gender}
                        onChange={handleChange}
                        
                      >
                        <option value="Gender" className="p-2 text-dark" disabled hidden>
                          Gender
                        </option>
                        <option value="Female" className="p-2 text-dark">
                          Female
                        </option>
                        <option value="Male" className="p-2 text-dark">
                          Male
                        </option>
                        <option value="Other" className="p-2 text-dark">
                          Other
                        </option>
                        <option value="Prefer not to say" className="p-2 text-dark">
                          Prefer not to say
                        </option>
                      </select>
                      {errors.gender && touched.gender ? (
                        <div className="error-text">{errors.gender}</div>
                      ) : null}
                    </div>
                    <div className="d-flex mt-3 position-relative px-4 justify-content-between">
                      <select
                        className="form-select form-select-sm text-dark dateSelect"
                        name="month"
                        value={values.month}
                        onChange={handleChange}
                       
                      >
                        <option value="MM" disabled hidden>
                          MM
                        </option>
                        {monthData.map((item, key) => {
                          return (
                            <option value={item} key={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                      {errors.month && touched.month ? (
                        <div className="error-text">{errors.month}</div>
                      ) : null}
                             <select
                        className="form-select form-select-sm text-dark dateSelect"
                        name="date"
                        value={values.date}
                        onChange={handleChange}
                        
                      >
                        <option value="DD" disabled  hidden>
                          DD
                        </option>
                        {days.map((item, index) => {
                          return (
                            <option value={item} key={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                      {errors.date && touched.date ? (
                        <div className="error-text">{errors.date}</div>
                      ) : null}
                             <select
                        className="form-select form-select-sm text-dark dateSelect"
                        name="year"
                        value={values.year}
                        onChange={handleChange}
                        
                      >
                        <option value="YY" disabled hidden>
                          YY
                        </option>
                        {year.map((item, index) => {
                          return (
                            <option value={item} key={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                      {errors.year && touched.year ? (
                        <div className="error-text">{errors.year}</div>
                      ) : null}
                    </div>
                  </>
                </>
              ) : (
                <>
                {/* Bussiness Account */}
                <div className="mt-3 position-relative px-4">
                  <select
                    className="form-select form-select-sm AccountType"
                    value={values.bsCategory}
                    name="bsCategory"
                    onChange={handleChange}
                  >
                    <option value="BsCategory" className="text-danger" disabled hidden>
                      Business Category
                    </option>
                    {businessTypes.map((items) => {
                      return (
                        <option value={items.name} key={items.id}>
                          {items.name}
                        </option>
                      );
                    })}
                  </select>
                  {errors.bsCategory && touched.bsCategory ? (
                    <div className="error-text">{errors.bsCategory}</div>
                  ) : null}
                  </div>
                  <div className="mt-3 position-relative px-4">
                    <label className="Labelsignup">Business Name</label>
                    <MdInfo className="Mdinfo" color="black" size={20}/>
                    <input
                      className="form-control input-btn padd"
                      type="text"
                      placeholder="Business Name"
                      name="bsName"
                      value={values.bsName}
                      onChange={handleChange}
                    />
                    {errors.bsName && touched.bsName ? (
                    <div className="error-text">{errors.bsName}</div>
                    ) : null}
                  </div>
                  {/* <div className="mt-3 position-relative px-4">
                    <label className="Labelsignup">Full name of business page user</label>
                    <MdInfo className="Mdinfo" color="black" size={20}/>
                    <input
                      className="form-control input-btn padd"
                      type="text"
                      placeholder="Full name of business page user"
                      name="fsName"
                      value={values.fsName}
                      onChange={handleChange}
                    />
                    {errors.fsName && touched.fsName ? (
                    <div className="error-text">{errors.fsName}</div>
                    ) : null}
                  </div> */}
                  <div className="mt-3 position-relative px-4">
                  <label className="Labelsignup">Email Address</label>
                  <MdEmail className="Mdinfo" color="black" size={20}/>
                    <input
                      className="form-control input-btn padd"
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      autoComplete="off"
                      value={values.email}
                      onChange={handleChange}
                    />
                    {/* {errors.email && touched.email
                      ? setErrorField(errors.email)
                      : null} */}
                  </div>
                </>
              )}
              {/* ////////////// Email ////////////////////// */}
            </div>
            <Row>
              <Col lg="12" md="12" sm="12" xs="12"></Col>
              <Col lg="12" md="12" sm="12" xs="12">
                <div className="mt-3 position-relative px-4">
                <label className="Label">Password</label>
                <IoMdLock className="IoMdLock" color="black" size={20} />
                  <input
                    className="form-control input-btn padd"
                    name="password"
                    onChange={handleChange}
                    value={values.password}
                    autoComplete="new-password"
                    type={passwordShown ? "text" : "password"}
                    placeholder="Password"
                  />
                  {passwordShown === true?
                   <AiFillEye onClick={() => setPasswordShown(false)} className="eyeInvisible" color="black" size={20}/>:
                   <AiFillEyeInvisible onClick={() => setPasswordShown(true)} className="eyeInvisible" color="black" size={20}/>
                  }
                   {/* <AiFillEyeInvisible onClick={togglePasswordVisiblity} className="eyeInvisible" color="black" size={20}/> */}
                </div>
                {/* {errors.password && touched.password
                  ? setErrorField(errors.password)
                  : null} */}
              </Col>
              <Col lg="12" md="12" sm="12" xs="12">
                <div className="mt-3 position-relative px-4">
                <label className="Label">Confirm Password</label>
                <IoMdLock className="IoMdLock" color="black" size={20} />
                  <input
                    className="form-control input-btn padd"
                    value={values.confirmPassword}
                    name="confirmPassword"
                    type={passwordShown2 ? "text" : "password"}
                    placeholder="Confirm Password"
                    onChange={handleChange}
                  />
                  {passwordShown2 === true?
                   <AiFillEye onClick={() => setPasswordShown2(false)} className="eyeInvisible" color="black" size={20}/>:
                   <AiFillEyeInvisible onClick={() => setPasswordShown2(true)} className="eyeInvisible" color="black" size={20}/>
                  }
                </div>
              </Col>
              {/* Button */}
              <Col lg="12" md="12" sm="12" xs="12" className="d-flex justify-content-center p-4 pb-3">
                <Button
                  type="submit"
                  className="submit-btn ib-btn w-100 mx-2"
                  disabled={!(dirty && isValid)}
                  onClick={() => {
                    document.documentElement.scrollTop = 0;
                  }}
                >
                  Continue
                </Button>
              </Col>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <p className="dontHave">Already have an account?</p>
                <Button className="signup_button" 
                  onClick={() => {
                    signUpPageChange()
                  }
                }>
                <b>Sign In</b></Button>
              </div>
            </Row>
          </Form>
        )}
      </Formik>
      { otpModal && <OtpModel setOTPModal={(() => setOTPModal(!otpModal))} otpModal={otpModal}/> }
    </>

  );
};

export default Signup;