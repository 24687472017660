import React, { useRef, useEffect, useState } from "react";
import Markers from "./Marker";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import axios from "axios";
import UserPanel from "./UserPanel";
import { Col, Row } from "reactstrap";
import AddEvent from "../AddEventsPopup/AddEvent";
import AddMicroblog from "../AddMicroblogPopup/AddMicroblog";
import MicroblogActivities from "../AddMicroblogPopup/MicroblogActivities";
import { useSelector } from "react-redux";
import "../../../assets/styles/Dashboard/Dashboard.css"
// ... variables
import { useLocation } from "react-router-dom";
import { Server2, Socket } from "../../../redux/actions/auth/auth.actions";
let size = window.innerWidth;
const mapContainerStyle = {
  width: size < 768 ? "95%" : "100%",
  height:size < 768 ?"calc(100vh - 30vh)": "calc(100vh - 153px)",
  borderRadius: "10px",
  marginLeft: "14px",
};

const Map = ({setNavMaps , NavMaps , UpRef}) => {
  const { state } = useLocation();
  const { item } = state;
  const NormalUser = useSelector((state) => state?.auth?.user?.user);
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;
  const maps = useRef(null);
  const [data, setData] = useState([]);
  const [list, setList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [MicroblogOpen, setMicroblogOpen] = useState(false);
  const [ActivitiesOpen, setActivitiesOpen] = useState(false);
  const [MicroData, setMicroData] = useState([]);
  const mapContainerRef = useRef(null);
  const [type, setType] = useState("user");
  const [check, setCheck] = useState("user");
  const [HoverCheck, setHoverCheck] = useState("");
  const timeoutRef = useRef(null); // create a ref to store the timeout id

  // console.log(check,"check")
  const [map, setMap] = useState(null);
  useEffect(() =>{
    Socket.on("newMicroBlog" , (data) =>{
      console.log("i am working")
      MicroApi()
    })
  },[Socket])

  const UsersApi = () => {
    setData([]);
    var config = {
      method: "get",
      url: Server2 + `/users/AllUsers?id=${NormalUser?._id}&type=${false}&platform=web`,
    };
    axios(config)
      .then(function (response) {
        setType("user");
        setData(response.data);
        const bound = maps.current.getBounds();

        let val = response.data.filter((it) => {
          return inBounds(it, bound);
        });
        if (val) {
          setList(val);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const BusinessApi = () => {
    setData([]);
    var config = {
      method: "get",
      url: Server2 + `/users/AllUsers?id=${NormalUser?._id}&type=${true}`,
    };
    axios(config)
      .then(function (response) {
        setType("business");
        setData(response.data);
        const bound = maps.current.getBounds();

        let val = response.data.filter((it) => {
          return inBounds(it, bound);
          // return it?._id == NormalUser?._id ? null : inBounds(it, bound);
        });
        if (val) {
          setList(val);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const EventApi = () => {
    setData([]);
    setList([]);

    var config = {
      method: "get",
      url: Server2 + "/events/allEvents",
      // headers: { "Content-Type": "application/json" },
    };
    axios(config)
      .then(function (response) {
        setData(response.data);
        setType("event");
        
        const bound = maps.current.getBounds();

        let val = response.data.filter((it) => {
          return inBounds(it, bound);
        });
        if (val) {
          setList(val);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const MicroApi = () => {
    setData([]);
    setList([]);
    var config = {
      method: "get",
      url: Server2 + `/microblogs/allMicroblogs?id=${NormalUser?._id}`,
      // headers: { "Content-Type": "application/json" },
    };
    axios(config)
      .then(function (response) {
        if (response.data) {
          setType("micro");
          setData(response.data);
          
          const bound = maps.current.getBounds();

          let val = response.data.filter((it) => {
            return inBounds(it, bound);
          });
          if (val) {
            setList(val);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const inBounds = (points, bound) => {
    const a = points.location.coordinates[0].coords;
    var lat = a?.lat;
    var lng = a?.lng;
    var point = [lat ? lat : 0, lng ? lng : 0];
    var lngs = (point[0] - bound._sw.lat) * (point[0] - bound._ne.lat) < 0;
    var lats = (point[1] - bound._sw.lng) * (point[1] - bound._ne.lng) < 0;
    return lngs && lats;
  };
  // add new effect hook
  useEffect(() => {
    console.log("render")
    UsersApi();
    if (maps.current) return;
    maps.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      accessToken: process.env.REACT_APP_MAPBOX_KEY,
      style: `${process.env.REACT_APP_MAPBOX_STYLE}?key=${process.env.REACT_APP_MAPBOX_API_KEY}`,
      center:
        item == null
          ? [
              NormalUser.location.coordinates[0].coords.lng,
              NormalUser.location.coordinates[0].coords.lat,
            ]
          : item,
      zoom: 5,
    });
    maps.current.addControl(new mapboxgl.NavigationControl(), "bottom-right");
    maps.current.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        marker: false,
      })
    );
    setMap(maps.current);
    setNavMaps(maps.current);

    return () => maps.current.remove();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!maps.current) return; // wait for map to initialize

    maps.current.once("moveend", (e) => {
      const bound = e.target.getBounds();

      let val = data.filter((it) => {
        return inBounds(it, bound);
      });
      if (val) {
        setList(val);
      }
    });
  }, [list]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    Socket.on("newuseronline", (data) => {
      if(type==="business"){
        BusinessApi()
      }else if (type==="user"){
        UsersApi()
      }
    });
    return () => {
      Socket.off("newuseronline");
    };
  }, [list]); // eslint-disable-line react-hooks/exhaustive-deps
  
  // changes side pane on hover on panel select buttons
  const handleMouseMove = () => {
    // Clear existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    // Set a new timeout to process hover after delay
    timeoutRef.current = setTimeout(() => {
      // console.log(`HoverCheck: ${HoverCheck}, executing API call`);
      if (HoverCheck === "user") {
        UsersApi();
      } else if (HoverCheck === "micro") {
        MicroApi();
      } else if (HoverCheck === "event") {
        EventApi();
      } else if (HoverCheck === "business") {
        BusinessApi();
      }
    }, 500);
  };

  const handleMouseLeave = () => {
    // Clear timeout and call API based on the `check` state
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    console.log(`Mouse left. Triggering API based on check state: ${check}`);
    if (check === "user") {
      UsersApi();
    } else if (check === "micro") {
      MicroApi();
    } else if (check === "event") {
      EventApi();
    } else if (check === "business") {
      BusinessApi();
    }

    setHoverCheck(""); // Reset hoverCheck to default state
  };

  const handleButtonClick = (type, apiCall) => {
    setCheck(type);
    setHoverCheck(""); // Clear hoverCheck on button click
    apiCall(); // Trigger the corresponding API call
  };

return (
  <div style={{ overflow: "hidden" }}>
    <Row>
      <Col md="8" lg="9" sm="12" xm="12">
        <div ref={mapContainerRef} style={mapContainerStyle} className="mb-2 map-Main">
          {data && map && (
            <Markers
              map={map}
              places={data}
              data={data}
              type={type}
              isActiv={() => setActivitiesOpen((e) => !e)}
              MicroData={(item) => setMicroData(item)}
            />
          )}
          <div className="HomeButtons pt-sm-2" onMouseMove={handleMouseMove}  onMouseLeave={handleMouseLeave}>
            {[
              { type: "user", apiCall: UsersApi, img: "/Images/prof-float-ico-1.png" },
              { type: "event", apiCall: EventApi, img: "/Images/loc-float-ico-1.png" },
              { type: "micro", apiCall: MicroApi, img: "/Images/chat-float-ico-1.png" },
              { type: "business", apiCall: BusinessApi, img: "/Images/buy-cart-1.png" },
            ].map(({ type, apiCall, img }) => (
              <button
                key={type}
                className="bg-transparent border-0 pt-3"
                onClick={() => handleButtonClick(type, apiCall)}
                onMouseOver={() => setHoverCheck(type)}
              >
                <img src={img} className="" height={45} alt={`${type}-icon`} />
              </button>
            ))}
          </div>
            {isOpen && (
              <div className="main-event-bg">
                <AddEvent
                  isOpen={isOpen}
                  setIsOpen={() => setIsOpen((e) => !e)}
                  handleClose={() => setIsOpen((e) => !e)}
                  handleApi={()=>EventApi()}
                />
              </div>
            )}
            {MicroblogOpen && (
              <div className="main-event-bg  ">
                <AddMicroblog
                  MicroblogClose={() => setMicroblogOpen((e) => !e)}
                />
              </div>
            )}
            {ActivitiesOpen && (
              <div className="main-event-bg">
                <MicroblogActivities
                  ActivitiesClose={(e) => setActivitiesOpen(e)}
                  MicroData={MicroData}
                />
              </div>
            )}
          </div>
        </Col>
        <Col md="4" lg="3" sm="12" xm="12">
          <UserPanel
            list={list}
            type={type}
            length={list.length}
            isopen={() => setIsOpen((e) => !e)}
            isMicro={() => setMicroblogOpen((e) => !e)}
            isActiv={() => setActivitiesOpen((e) => !e)}
            MicroData={(item) => setMicroData(item)}
            UpRef= {UpRef}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Map;
